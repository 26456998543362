<template>
    <div >     
      <!---->
      <br>
      <el-date-picker
               v-model="settleDate"
               size="small"
               class="filter-item"
               type="date"
               value-format="yyyy-MM-dd"
     
               :picker-options="pickerOptions"
               :start-placeholder="listQuery2.settle_date"
               >
             </el-date-picker>
       
       <el-button  size="small" type="success"  @click="setSetleDate()" align="left"  style="margin-left: 10px;">更新入帳日</el-button>
  
            <el-table
        :header-cell-style="{color:'#20638C'}"
        v-loading="listLoading"
        :data="list1" style="width: 100%;"
        :cell-style="{padding: '0', height: '40px'}"
        
       >
        <el-table-column align="center" prop="no"   label="編號" min-width="50%"  />
        <el-table-column align="center" prop="trade_date"   label="交易結帳日" min-width="80%"  />
        <el-table-column align="center" prop="tappay_deposit_no"        label="入帳編號" min-width="80%"  />
        <el-table-column align="center" prop="agent_amount"         label="訂單金額"  />
        <el-table-column align="center" prop="agent_charge"       label="交易手續費" />
        <el-table-column align="center" prop="agent_deposit_amount"    label="實際入帳金額"  />
        <el-table-column align="center"  label="選取" >
        <template slot-scope="scope">             
               <el-checkbox  key="appay_deposit_no"  @change="check(scope.row)"> </el-checkbox>
        </template>
        </el-table-column>  
      </el-table>
      <el-dialog width="700px"  :visible.sync="dialogSetSettleDay" @close="dialogOnClose()" >
  
  <el-row align="left" style="font-size:20px">更新入帳日</el-row>
  <br>
  <span :style="{color:'red',fontWeight:'bold',fontSize:'18px'}">
    請注意:入帳日期僅能設定一次!!
      </span>
  <el-divider content-position="left" style="font-size:16px">入帳日期</el-divider>
    
    <el-row align="left" > {{this.settleDate}}&emsp;</el-row>
  
     <el-divider content-position="left" style="font-size:16px">交易資訊</el-divider>
  
    <br>
    
    <el-table
        :header-cell-style="{color:'#20638C'}"
        :data="list2" style="width: 100%;"
        :cell-style="{padding: '0', height: '40px'}"
        v-if="checked" 
        @sort-change="tableSortChange"
       >
        <el-table-column align="center" prop="no"   label="編號" min-width="50%"  />
        <el-table-column align="center" prop="trade_date"   label="交易結帳日" min-width="80%"  />
        <el-table-column align="center" prop="tappay_deposit_no"        label="入帳編號" min-width="80%"  />
        <el-table-column align="center" prop="agent_amount"         label="訂單金額"  />
        <el-table-column align="center" prop="agent_charge"       label="交易手續費" />
        <el-table-column align="center" prop="agent_deposit_amount"    label="實際入帳金額"  />
      </el-table>
      <br>
      <el-row align="left" >  入帳金額小計: {{this.agent_deposit_amount}}&emsp;</el-row>
      <br>             
         <div style="text-align: center">
          <el-button size="small" @click="dialogSetSettleDay = false">
            取消
          </el-button>
          <el-button size="small" type="primary" @click="setSettleDateConfirm()">
            確定
          </el-button>
          </div>
        </el-dialog>
        <!---->
         </div> 
    
  </template>
  
  <script>
  import { mixins } from '@/views/common/mixins.js';
  
  export default {
  
    components: {
      //Pagination,
      //ParkingSpaceSetting,
    },
    mixins: [mixins],
    data() {
      return {
        result: '',
        dialogWidth: 0,

        listLoading: false,
        listQuery: {
          serviceId: this.$store.state.currentAccount.serviceInfo.id,
          name: undefined,
          stationId: undefined,
          chargePointId: undefined,
          page: 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
          size: 15,
    
        },


        //--------------
        list1: [],
        list2: [],
        checked :false,
        dialogSetSettleDay :false,
        settleDate :undefined,
        agent_deposit_amount:0,
        listQuery2: {
                settle_date:undefined,
          page: 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
          size: 15,  
        },
        pickerOptions: {
          disabledDate(time) {
            const today = new Date();
            const threeMonthsAgo = new Date();
            threeMonthsAgo.setMonth(today.getMonth() - 3);
  
            // 將時間的時分秒部分設置為 0:0:0 以便只比較日期部分
            today.setHours(0, 0, 0, 0);
            threeMonthsAgo.setHours(0, 0, 0, 0);
  
            return time.getTime() > today.getTime() || time.getTime() < threeMonthsAgo.getTime();
          }
        },
        //----------------
      };
    },
    watch: {     
      
      'settleDate': function (val) {
      this.settleDate = val;
     },
    },
    created() {

      this.resetPage(this.getList1)

    },
    beforeDestroy() {
    },
    mounted() {
      window.onresize = () => {
        return (() => {
          this.dialogWidth = this.setDialogWidth()
        })()
      }
    },
    methods: {
          //-----------------------------
      getList1() {
        this.listLoading = true
        ///api/v1/tappay_modify/query/sattle_list
        this.axios.get('/api/v1/tappay_modify/query/sattle_list').then(res=> {
          //console.log("code:",res.data.code);   
          //console.log("message:",res.data.message);   
          this.list1 = res.data.data
          //this.list2 = this.list1
        
          //this.total = res.data.data.totalElements
          this.listLoading = false
          console.log("list2:",this.list1);   
          //let tempstring=""
           /* 讓 this.list的Map加入一個key為no */
          this.list1.map( ( item , index ) => {
               item.checked = false
               //item.no = index +1   
               //tempstring = item.tappay_deposit_no.slice(1, 5) + "/"+ item.tappay_deposit_no.slice(5, 7) +"/"+ item.tappay_deposit_no.slice(7, 9)   
               item.trade_date =    item.tappay_deposit_no.slice(1, 5) + "/"+ item.tappay_deposit_no.slice(5, 7) +"/"+ item.tappay_deposit_no.slice(7, 9)    
               item.sortdata = item.tappay_deposit_no.slice(1, 9)
               //tempstring=""
               return item
           })
           this.list1.sort((a, b) => {
            return a.sortdata - b.sortdata;
          })
          this.list1.map( ( item , index ) => {
               //item.checked = false
               item.no = index +1   

               return item
           })
         console.log("list2:",this.list1);   
        })
        this.listLoading = false
  
      },
      check(row)
      {     
        //console.log("row.agent_deposit_amount:",row.agent_deposit_amount);   
        if(row.checked){
         
          row.checked = false
          let i = this.list2.map(item => item.no).indexOf(row.no) // find index of your object
          this.list2.splice(i, 1) 
          if(this.list2.size == 0){
            this.checked = false  
          }
          this.agent_deposit_amount -= row.agent_deposit_amount
          
        }else{
          row.checked = true  
          this.list2.push(row);
          this.checked = true  
          this.agent_deposit_amount += row.agent_deposit_amount
        }
        //console.log("agent_deposit_amount:",this.agent_deposit_amount);   
        //console.log("row.checked",row.checked);        
      },
      setSetleDate(){
        console.log("setSetleDate" + this.settleDate + "-" + this.list2.length )
        if(this.settleDate == undefined){
           this.$message({showClose: true,message: "請選擇入帳日期",type: 'warning'})
        }else if(this.list2 == undefined || this.list2.length  < 1){
          this.$message({showClose: true,message: "請選取欲變更之交易紀錄",type: 'warning'})
        }
        else{
          this.dialogSetSettleDay = true;
        }
      },    
      setSettleDateConfirm(){
        this.$confirm('提示', {
                message: '請注意-入帳日期僅能設定一次',
                confirmButtonText: '確認',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                //console.log('confirm setSetleDate') 
                  //---
              this.targetUrl ="/api/v1/tappay_modify/update/sattle_list"
              //console.log("this.list2.tappay_deposit_no",this.list2.map(item => item.tappay_deposit_no))
        this.axios.post(this.targetUrl, {
        sattleDate: this.settleDate,
        sattleList: this.list2.map(item => item.tappay_deposit_no),
      })
      .then(response => {
        if(response.data.code == 200){
          this.$message({showClose: true,message: "入帳日期設定成功",type: 'info'})
        }else{
          this.$message({showClose: true,message:response.data.message +":" + response.data.data ,type: 'warning',duration: 8000})
        }
        this.list1 = undefined;
        this.list2 = [];   
       
        this.agent_deposit_amount = 0; 
        this.resetPage(this.getList1)
      }).catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      })
        this.dialogSetSettleDay=false
 
        
              //---                    
              }).catch(()=> {
                console.log('cancel setSetleDate')
              })
              
      },
      dialogOnClose(){
        this.sattleDate = undefined;   
         },
      //--------------------------
    }
  }
  </script>
  